<template>
	<div class="goods-detail">
		<top-search :preselection="2" @changeMode="changeMode"></top-search>
		<!-- <div class="category_lists" ref="scrollBox">
			<div class="item" ref="scrollItem" @click="chooseCategory(index, $event)"
				:class="current_category == index ? 'active' : ''" v-for="(item, index) in 20">
				淘宝转让
				<div :class="current_category == index ? 'active' : ''" class="line"></div>
			</div>
		</div> -->
		<div v-loading="info_loading">
			<div class="center_content">
				<div class="left_content">
					<div class="tabs" v-if="goods_info.is_online_shop && goods_info.extend_data">
						<div :class="goods_show ==1 ? 'active':''" @click="goods_show = 1">网店<div v-if="goods_show==1" class="sanjiao" ></div>
						</div>
						<div :class="goods_show ==2 ? 'active':''" @click="goods_show = 2">公司<div v-if="goods_show==2" class="sanjiao"></div>
						</div>
						<div :class="goods_show ==3 ? 'active':''" @click="goods_show = 3">商标<div v-if="goods_show==3" class="sanjiao"></div>
						</div>
					</div>
					<div class="goods_content">
						<div class="goods_img" v-if="goods_show ==1 && goods_info.goods_image">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.goods_image.split(',')" :key="index">
									<img :src="$img(item,{size:'big'})" alt="">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_img" v-if="goods_show ==2 && goods_info.extend_data">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.extend_data.company_image.split(',')" :key="index">
									<img :src="$img(item,{size:'big'})" alt="">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_img" v-if="goods_show ==3 && goods_info.extend_data">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.extend_data.logo_image.split(',')" :key="index">
									<img :src="$img(item,{size:'big'})" alt="">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_info">
							<div class="top_info" v-if="goods_show==1">
								<div class="name">{{goods_info.goods_name}}</div>
								<div class="sub_info">
									<div class="number">编号：{{goods_info.goods_code}}</div>
									<div class="copy" @click="$util.copy(goods_info.goods_code)">复制</div>
									<div class="time">{{$util.timeStampTurnTime(goods_info.modify_time)}}</div>
								</div>
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div>
									</div>
								</div>
							</div>
							<div class="top_info" v-if="goods_show==2">
								<div class="name">{{goods_info.extend_data.company_name}}</div>
								
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.extend_data.company_attr_format" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.extend_data.company_attr_format" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div>
									</div>
								</div>
							</div>
							<div class="top_info" v-if="goods_show==3">
								<div class="name">{{goods_info.extend_data.logo_name}}</div>
								
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.extend_data.logo_attr_format" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.extend_data.logo_attr_format" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div>
									</div>
								</div>
							</div>
							<div class="bottom_info">
								<div class="left">
									<img class="logo" @click="toMemberDetail(goods_info.member_info.member_id)" v-if="goods_info.member_info" :src="$img(goods_info.member_info.headimg)" @error="goods_info.member_info.headimg = defaultHeadImage" alt="">
									<img src="@/assets/images/newImg/message.png" alt="" @click="handleTabs('comment')">
									<img src="@/assets/images/newImg/kefu.png" alt="" @click="toViewChat()">
									<img src="@/assets/images/newImg/invitation.png" alt="" @click.stop="toOfferGoods()">
								</div>
								<div class="purchase" v-if="goods_info.goods_state && !goods_info.selected_tender" @click="handleTabs('tender')">报价</div>
								<div class="purchase grey" v-else>报价</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_content" v-if="goods_info.member_info">
					<div class="top_info">
						<img @click="toMemberDetail(goods_info.member_info.member_id)" :src="$img(goods_info.member_info.headimg)" @error="goods_info.member_info.headimg = defaultHeadImage" alt="">
						<div class="name" @click="toMemberDetail(goods_info.member_info.member_id)">{{goods_info.member_info.nickname}}</div>
						<div class="card" v-if="goods_info.member_info.is_agent"><img class="is_agent" src="@/assets/images/newImg/isAgent.png" />经纪人</div>
					</div>
					<div class="btns">
						<div class="member_tit" @click="toMemberDetail(goods_info.member_info.member_id)">主页</div>
						<div class="btn" @click="toViewChat()">客服</div>
						<div class="btn wx">
							微信
							<img v-if="goods_info.member_info.wechat_personal_qrcode" class="wechat_img" :src="$img(goods_info.member_info.wechat_personal_qrcode)" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="detail_content" id="detail_content">
				<div class="tabs">
					<div class="item" v-for="(item,index) in content_sort" :key="index" :class="{ 'active': detailStatus == item.id }" @click="handleTabs(item.id)">{{item.name}}<div
							class="line"></div>
					</div>
				</div>
				<!-- 报价 -->
				<div class="offer" v-if="detailStatus == 'tender'">
					<div class="add_offer" v-if="goods_info.goods_state && !goods_info.selected_tender">
						<el-image class="headimg" :src="$img(member.headimg ? member.headimg : defaultHeadImage)" @error="member.headimg = defaultHeadImage" fit="cover"></el-image>
						<div class="offer_content">
							<div class="offer_main">
								<div class="top">
									<el-input class="offer_desc" type="textarea" v-model="offerData.offercontent" resize="none" placeholder="描述"></el-input>
									<div class="line"></div>
									<el-input type="textarea" v-model="offerData.offerPrice" resize="none" placeholder="价格"></el-input>
								</div>
								<div class="imgs" >
									<div class="img_item" v-for="(item,index) in offerData.offerImgList" :key="index">
										<el-image :src="$img(item)" :preview-src-list="offerData.offerImgBigList" fit="cover"></el-image>
										<div @click="deleteOfferImg(index)" class="el-icon-error"></div>
									</div>
								</div>
							</div>
							<div class="offer_btns" @click="addOffer()">投标</div>
						</div>
						<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleOfferSuccess(file, fileList); } " :on-exceed="handleOfferExceed" :limit="5" :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
							<i class="el-icon-circle-plus-outline"></i>
						</el-upload>
					</div>
					<div class="offer_list" v-loading="loading">
						<div class="offer-grid" v-for="(item) in offerList" :key="item.id">
							<div class="offer-info-first">
								<div class="info-left">
									<img :src="$img(item.seller_headimg ? item.seller_headimg : defaultHeadImage)" @error="item.seller_headimg = defaultHeadImage" >
									<span class="nickname">{{ item.seller_nickname }}</span>
									<p>{{ $util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/') }}</p>
									<!-- <p style="margin-left: 15px; cursor: pointer;" v-if="item.member_id == member.member_id" @click="offerEdit(item)">编辑</p>
									<p style="margin-left: 15px;cursor: pointer;"  v-if="item.member_id == member.member_id" @click="offerDelete(item)">删除</p> -->
								</div>
								<div class="info-main">
									<div>报价</div>
									<div>
										<span>￥</span>
										<span>{{ item.price }}</span>
									</div>
								</div>
								<div class="info-right">
									<div :class="item.status==1 ? 'tender_detail':''" v-if="goods_info.selected_tender">{{item.status==1?'中标':'废标'}}</div>
									<div class="select_act" v-if="goods_info.is_publisher && item.status==0" @click="selectGoodsTender(item.tender_id)">选择</div>
									<div class="wechat">微信
										<img v-if="item.seller_wechat_personal_qrcode" class="wechat_img" :src="$img(item.seller_wechat_personal_qrcode)" alt="">
									</div>
									<div @click="toViewChat(item.seller_member_id)">消息</div>
								</div>
							</div>
							<div class="offer-info-second">
								<div>{{ item.content }}</div>
								<div class="offer-info-imgs" v-if="item.images">
									<!-- <img class="img_item" v-for="(imagesItem) in item.images.split(',')" :key="imagesItem" :src="$img(imagesItem)" alt=""> -->
									<el-image class="img_item" v-for="(imagesItem) in item.images.split(',')" :key="imagesItem" :src="$img(imagesItem)" fit="cover" :preview-src-list="item.imagesList"></el-image>
								</div>
							</div>
						</div>
					</div>
					<div class="pager">
						<el-pagination background :pager-count="5" :total="offerTotal" layout="prev,pager,next,jumper,total"
							:current-page.sync="offerCurrentPage" :page-size.sync="offerPageSize" @size-change="handleOfferPageSizeChange"
							@current-change="handleOfferCurrentPageChange" hide-on-single-page></el-pagination>
					</div>
				</div>
				<!--评论  -->
				<div class="evaluate" v-if="detailStatus == 'comment'">
					<div class="evaluate-bottom evaluate-height">
						<el-image class="headimg" :src="$img(member.headimg ? member.headimg : defaultHeadImage)" fit="cover" @error="member.headimg = defaultHeadImage"></el-image>
						<div class="evaluate-bottom-content">
							<div class="evaluate-main">
								<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="comment" resize="none" placeholder=""></el-input>
								<div class="imgs" >
									<div class="img_item" v-for="(item,index) in imgList" :key="index">
										<el-image :src="$img(item)"  :preview-src-list="imgBigList"></el-image>
										<div @click="deleteImg(index)" class="el-icon-error"></div>
									</div>
								</div>
							</div>
							<div class="evaluate-btns" @click="sendComment()">
								发送
							</div>
						</div>
						<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :on-exceed="handleExceed" :limit="5" :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
							<i class="el-icon-circle-plus-outline"></i>
						</el-upload>
					</div>
					<div class="evaluate-info" v-loading="loading">
						<div class="evaluateItem" v-for="(item,index) in commentList" :key="item.id">
							<div class="evaluate-top">
								<div class="evaluate-top-first">
									<img class="headimg" :src="$img(item.member_headimg ? item.member_headimg : defaultHeadImage)" alt="" @error="item.member_headimg = defaultHeadImage">
									<span>{{ item.member_nickname }}</span>
									<span>{{ $util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/') }}</span>
									<p class="action" @click="handleReply(index,item)">{{item.showReply ? '收起' : '回复'}}</p>
									<p class="action" v-if="item.member_id == member.member_id" @click="handleDelete(item,index)">删除</p>
								</div>
								<div class="evaluate-top-second" v-if="item.images.length">
									<div class="content">{{ item.content }}</div>
									<div class="images">
										<el-image v-for="itemImg in item.images" :key="itemImg" fit="cover" :src="$img(itemImg)" :preview-src-list="item.imagesList"  alt="" v-if="itemImg" class="review_img"></el-image>
									</div>
								</div>
								<div class="evaluate-bottom evaluate-height" v-if="item.showReply">
									<div class="evaluate-bottom-content">
										<div class="evaluate-main">
											<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="replyComment" resize="none" :placeholder="`回复@${Object.keys(replyChild).length ? replyChild.nickname : reply.member_nickname}`"></el-input>
											<div class="imgs" >
												<div class="img_item" v-for="(item,index) in replyImgList" :key="index">
													<el-image :src="$img(item)"  :preview-src-list="replyImgBigList" fit="cover"></el-image>
													<div @click="deleteImg(index)" class="el-icon-error"></div>
												</div>
											</div>
										</div>
										<div class="evaluate-btns" v-if="Object.keys(replyChild).length" @click="confirmReply(replyChild)">
											回复
										</div>
										<div class="evaluate-btns" v-else @click="confirmReply(reply)">
											回复
										</div>
									</div>
									<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :on-exceed="handleExceed" :limit="5" :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
										<i class="el-icon-circle-plus-outline"></i>
									</el-upload>
								</div>
							</div>
							<div class="evaluate-content" >
								<div class="replys" v-if="item.show && item.child">
									<div class="replyItem" v-for="replayItem in item.child" :key="replayItem.id" :id="`evaluateRefs${replayItem.id}`">
										<div class="evaluate-content-first">
											<div>
												<img :src="$img(replayItem.headimg ? replayItem.headimg : defaultHeadImage)" alt="" @error="replayItem.headimg = defaultHeadImage">
											</div>
											<span>{{ replayItem.member_nickname }}</span>
											<i class="el-icon-caret-right" v-if=" replayItem.recommend_id != item.id"></i>
											<span v-if=" replayItem.recommend_id != item.id">{{ replayItem.recommend_member_nickname}}</span>
											<span>{{ $util.timeStampTurnTime(replayItem.create_time).split(' ')[0].replaceAll('-','/') }}</span>
											<p class="action" @click="handleReply(index,item,replayItem)">{{replayItem.showReply ? '收起':'回复'}}</p>
											<p class="action" v-if="replayItem.member_id == member.member_id" @click="handleDelete(replayItem,index)">删除</p>
										</div>
										<div class="evaluate-content-third">
											<div class="content">{{replayItem.content}}</div>
											<div class="images" v-if="replayItem.images.length">
												<el-image v-for="replayImg in replayItem.images" :key="replayImg" :src="$img(replayImg)"  :preview-src-list="replayItem.imagesList" alt="" fit="cover" v-if="replayImg"></el-image>
											</div>
										</div>
										<div class="evaluate-bottom evaluate-height" v-if="replayItem.showReply">
											<div class="evaluate-bottom-content">
												<div class="evaluate-main">
													<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="replyComment" resize="none" :placeholder="`回复@${Object.keys(replyChild).length ? replyChild.nickname : reply.member_nickname}`"></el-input>
													<div class="imgs" >
														<div class="img_item" v-for="(item,index) in replyImgList" :key="index">
															<el-image :src="$img(item)"  :preview-src-list="replyImgBigList" fit="cover"></el-image>
															<div @click="deleteImg(index)" class="el-icon-error"></div>
														</div>
													</div>
												</div>
												<div class="evaluate-btns" v-if="Object.keys(replyChild).length" @click="confirmReply(replyChild)">
													回复
												</div>
												<div class="evaluate-btns" v-else @click="confirmReply(reply)">
													回复
												</div>
											</div>
											<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :on-exceed="handleExceed" :limit="5" :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
												<i class="el-icon-circle-plus-outline"></i>
											</el-upload>
										</div>
									</div>
								</div>
								<div class="expand-more" v-if="item.number">
									<div class="show-more">
										<div v-if="item.number && !item.checkbox"  @click="getComments(item,index,comment_ids)">
											<span></span>
											<span>展开{{ item.number }} 条回复</span>
											<i class="el-icon-arrow-down"></i>
										</div>
										<div v-else-if="item.replyCurrentPage <= item.replyPageCount && item.checkbox " @click="getComments(item,index,comment_ids)">
											<span></span>
											<span>展开更多</span>
											<i class="el-icon-arrow-down"></i>
										</div>
									</div>
									<div class="pack-up" v-if="item.checkbox" @click="packUp(item,index)">
										<span>收起</span>
										<i class="el-icon-arrow-up"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pager">
						<el-pagination background :pager-count="5" :total="commentTotal" layout="prev,pager,next,jumper,total"
							:current-page.sync="commentCurrentPage" :page-size.sync="commentPageSize" @size-change="handleCommentPageSizeChange"
							@current-change="handleCommentCurrentPageChange" hide-on-single-page></el-pagination>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 报价弹框 -->
		<offer ref="offerRefs" :id="skuId"  @hendleData ="handleOfferList"></offer>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
import servicerMessage from "@/components/message/servicerMessage"
import topSearch from '@/components/top_search';
import detail from './detail';
export default {
	name: 'detail',
	mixins: [detail],
	components: {
		topSearch,
		servicerMessage
	},
};
</script>
<style lang="scss">
@import './detail.scss';
.el-image-viewer__canvas{
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 80%;
	height: 80%;
	img{
		object-fit: contain;
	}
}
.goods_content .el-carousel__indicators--horizontal{
	display: flex;
}
.review_dialog{
	.el-dialog{
		border-radius: 5px;
		margin-top: 30vh;
	}
	.el-dialog__header{
		padding: 0;
	}
	.el-dialog__body{
		padding: 14px !important;
	}
	.dialog-btns{
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content:space-between;
		.el-upload--picture-card{
			width: 22px;
			height: 22px;
			line-height:0;
			display: flex;
			align-items: center;
			background-color: transparent;
			border: none;
			.icon-tianjiatupian{
				color: rgba(48, 55, 61, 0.80);
				font-size: 22px!important;
			}
		}
		.el-button{
			height: 37px;
			padding: 0 53px;
			border-radius: 17px;
			background-color: #F7F8FB;
			border-color: #F7F8FB;
			color: #30373D;
			font-size: 14px;
			line-height: 19px;
			font-family: "PingFang SC";
			font-weight: 600;
		}
	}
	.evaluate-main{
		background-color: #F7F8FB;
		border-radius: 5px;
		padding: 14px;
		display: flex;
		flex-direction: column;
		.el-textarea{
			min-height: 80px;
			.el-textarea__inner{
				background-color: transparent;
				border: none;
				padding: 0;
				&::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
		}
	}
	.imgs{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.img_item{
			width: 50px;
			height: 50px;
			position: relative;
			margin-right: 15px;
			margin-top: 10px;
			.el-image{
				width: 50px;
				height: 50px;
				img{
					cursor: pointer;
					width: 100%;
					height: 100%;
					display: block;
					border-radius: 4px;
				}
			}
			.el-icon-error{
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
				transform: translate(50%,-50%);
			}
		}
	}
}
.goods-detail{
	.pager{
		.el-pagination{
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn-prev{
				margin-right: 15px !important;
			}
			.btn-next{
				margin-left: 15px !important;
			}
			.btn-prev,.btn-next{
				min-width: 20px;
				height: 20px;
				margin: 0;
				color: #30373D !important;
				font-weight: bolder !important;
				background-color: transparent;
			}
			.el-pagination__jump{
				margin-left: 20px;
				color: #86909C;
				font-size: 14px;
				line-height: 11px;
				display: flex;
				align-items: center;
				height: auto;
				.el-pagination__editor{
					margin: 0 8px;
					padding: 0;
					height: auto;
					width: auto;
					.el-input__inner{
						height:auto;
						min-width: auto;
						border: none;
						color: #30373D;
						padding: 3px 2px 2px;
						line-height: 14px;
						background-color: #F6F7FA;
						font-size: 14px;
					}
				}
			}
			.el-pagination__total{
				margin-left: 10px;
				color: #86909C;
				font-size: 14px;
				line-height: 21px;
				height: 21px;
			}
			.el-pager{
				display: flex;
				align-items: center;
				.btn-quicknext,.btn-quickprev{
					height: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: transparent;
				}
				.number{
					padding: 0;
					color: #30373D;
					line-height: 11px;
					height: auto;
					min-width: auto;
					padding: 5px 6px;
					font-size: 14px;
					margin: 0;
					font-weight: 500;
					margin-right: 10px;
					background-color: transparent;
					&:last-child{
						margin-right: 0;
					}
					&:not(.disabled).active{
						color: #30373D;
						font-weight: bolder;
						background-color: #F6F7FA;
						border-radius: 1px;
					}
				}
			}
		}
	}
}
</style>
